<template>
      <div class="footer">
        <v-row class="mt-1 row-footer">
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="8" class="d-flex justify-start align-center">
                <icons icon="pin-location" color="#1f3c87" style="margin-bottom:21px !important;"/>
                <router-link
                  :to="`/patient/nearme`"
                  style="text-decoration: none; margin-left:5px !important;"
                >
                  <p class="text" style="margin-bottom:21px !important;">{{ $t("page.nearme.list_view") }}</p>
                </router-link>
                <p class="text-sort" style="margin-left:15px !important; margin-bottom:21px !important">{{ $t("page.nearme.sort_by") }}</p>
                 <v-select
                  style="margin-left:5px !important;"
                  class="select-sort"
                  :items="sort"
                  dense
                  solo
                  v-model="nilai"
                  @change="$emit('changeSortBy', nilai)"
                ></v-select
              >
              </v-col>
             
              <v-col cols="5" class="d-flex justify-start align-center">
              </v-col>
                <!-- <v-row class="row-filter"> -->
                  <!-- <icons icon="filter" class="icon-filter" color="#1f3c87" /> -->
                  <!-- <p class="text-filter">All Filters</p> -->
                <!-- </v-row> -->
            </v-row>
          </v-col>
        </v-row>
      </div>
</template>
<script>

import icons from "@/components/elements/Icons.vue";

export default {
  components: {
    icons,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
    
      defaultSelected: {
        default: "distance",
      },
      nilai: this.value,
      sort: [],
     
    };
  },

  created() {
    this.sort = [
      { text: this.$t("page.nearme.distance"), value: "distance" },
      // { text: this.$t("page.nearme.price"), value: "price" },
      // { text: this.$t("page.nearme.city"), value: "city" },
    ];
  },
  watch: {
    value: function (new_val, old_val) {
      if (old_val) {
        this.nilai = new_val;
        console.log(this.nilai);
      }
    },
  },
  methods: {
    changeSorting(event) {
      console.log(event);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.column-icon {
  max-width: 4% !important;
}

.row-footer {
  margin-left: -7px;
}
.column-desc {
  margin-top: 4px;
  font-weight: bold;
}

.column-currency {
  margin-top: 4px;
}

.column-sort {
  margin-right: 20px;
  margin-left: -25px;
  margin-top: 4px;
}

.list-item-content {
  margin-top: -55px;
  margin-bottom: -45px;
}
.icon {
  margin-right: 10px;
}
.text {
  font-size: 12px;
  // margin-right: 50px;
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.card {
  border-radius: 10px;
  width: 100%;
}
.text-sort {
  font-size: 12px;
  margin-left: -90px;
}

.branch {
  margin-left: -17px;
  margin-top: 10px;
}

.service-filter {
  margin-top: 14px;
  margin-left: -7px;
  padding: 0;
}

.date-filter {
  margin-top: 14px;
  margin-left: 14px;
}

.filter-row-home-patient {
  margin-top: 5px;
  margin-bottom: -30px;
}

.filterTab {
  text-transform: capitalize;
  color: #1f3c87;
  font-weight: bold;
  padding-top: 0px;
  margin-top: 0px;
}

.row-filter {
  margin-top: 1px;
  flex-wrap: nowrap;
  margin-left: 545px;
  .text-filter {
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
    min-width: 200px;
  }
}

.column-button {
  margin-top: 10px;
  margin-left: 10px;
  padding-right: 2px;
}

.button {
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  color: white;
  background-color: #00a4b3 !important;
  border-radius: 4px;
  width: 100%;
  min-width: 130px;
  padding: 0 16px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  height: 25px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 130px;
  padding: 0 16px;
}

.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: transparent;
  margin-left: -20px !important;
  margin-top: -2px;
}

.column-select-sort {
  margin-left: -195px;
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: none;
    margin-left: -20px !important;
    margin-top: -2px;
  }
  .v-select.v-input--dense .v-select__selection--comma {
    margin: 5px 4px 3px 0;
    font-size: 12px;
  }
  .v-text-field.v-text-field--enclosed {
    padding: 0;
    margin-top: -9px;
  }
}

.select-sort {
  font-size: 12px;
  text-transform: capitalize;
  margin-left: -8px;
}

.column-icon-filter {
  margin-right: 10px;
  margin-left: 10px;
}

.v-input__control {
  margin-left: -20px !important;
  margin-top: -2px;
}

@media screen and (max-width: 600px) {
  .column-select-sort {
    margin-left: -175px;
    margin-top: 0px;
  }
  .mx-auto {
    margin-top: 10px;
  }
  .service-filter {
    margin-top: -20px;
    margin-left: -5px;
  }
  .date-filter {
    margin-top: -15px;
    margin-left: -5px;
  }
  .branch {
    margin-left: -5px;
  }

  .button {
    margin-bottom: 10px;
    margin-top: -45px !important;
  }

  .title {
    margin-top: 20px;
  }

  .image {
    width: 10px !important;
  }

  .column-desc {
    margin-right: 55px;
  }

  .column-sort {
    margin-right: 45px;
  }
}
</style>
<style>
.filter-row-home-patient .theme--light.v-icon {
  color: #1f3c87;
}

.filter-row-home-patient .v-select__selections {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 200;
}
.filter-row-home-patient .v-text-field input {
  font-size: 12px;
}
.filter-row-home-patient
  .v-autocomplete.v-input
  > .v-input__control
  > .v-input__slot {
  font-size: 12px;
  font-weight: 200;
}

.footer
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none;
}

.footer .v-text-field.v-text-field--solo .v-input__append-inner,
.v-text-field.v-text-field--solo .v-input__prepend-inner {
  align-self: center;
  margin-top: 0;
  margin-left: -80px;
}

@media screen and (max-width: 600px) {
  .footer .v-text-field.v-text-field--solo .v-input__append-inner,
  .v-text-field.v-text-field--solo .v-input__prepend-inner {
    align-self: center;
    margin-top: 0;
    margin-left: -50px;
  }
}
</style>
